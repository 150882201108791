<template>
  <div class="task">
    <div class="task_title" v-html="t.title"></div>

    <div class="select-question">
      <p>
        On Sunday, Tom gets up 
        <v-select
          :items="['at', 'in', 'on']"
          label="1"
          outlined
          class="select-question_input"
          style="width: 80px"
          v-model="select.select1"
          :disabled="disabledSelect"
        ></v-select> 
        10 o'clock. Then he reads his newspaper in the kitchen. He has breakfast at 11.30 and then he telephones 
        <v-select
          :items="['her', 'his', 'him']"
          label="2"
          outlined
          class="select-question_input"
          style="width: 90px"
          v-model="select.select2"
          :disabled="disabledSelect"
        ></v-select> 
        mother in Scotland.
      </p>

      <p>
        In the afternoon, at 1 pm, Tom plays tennis with his sister and after that, they 
        <v-select
          :items="['cook', 'do', 'have']"
          label="3"
          outlined
          class="select-question_input"
          style="width: 100px"
          v-model="select.select3"
          :disabled="disabledSelect"
        ></v-select> 
        dinner in a restaurant. At 6 pm, Tom swims for one hour and then he goes by bike to his brother's house. They talk and 
        <v-select
          :items="['hear', 'listen', 'play']"
          label="4"
          outlined
          class="select-question_input"
          style="width: 100px"
          v-model="select.select4"
          :disabled="disabledSelect"
        ></v-select> 
        to music.
      </p>
    </div>

    <v-btn 
        elevation="2" 
        @click="showAnswer" 
        color="#57b894" 
        :disabled="disabledBt"
        :class="[{hideBt: hideBt}, 'submitBt', 'main_button']"
    >Submit</v-btn>

    <div class="task-comment" v-if="comment">
        <div class="task-comment_head">
            <div class="correct" v-html="answerIs"></div>

            <v-sheet class="pa-5">
                <v-switch
                    @click="changeLang"
                    v-model="switchLang"
                    label="Translate"
                    inset
                ></v-switch>
            </v-sheet>
        </div>

        <div class="description" v-html="t.text"></div>

      <NextBt />
    </div>
    
  </div>
</template>


<script>
  import NextBt from '@/components/NextBt';

  export default {
    name: 'Task2',
    components: {NextBt},

    data: () => ({
      switchLang: false,
      comment: false,
      hideBt: false,
      t: null,
      lang: 'en',
      correct: ['at','his','have','listen'],
      select: {
        select1: null,
        select2: null,
        select3: null,
        select4: null
      },
      disabledSelect: false
    }),

    beforeMount() {
      this.changeLang();
    },
    methods: {
      changeLang() {      
        const translates = {
          en : {
            title: `<h2>Task 2.</h2><h3>For questions 1-4, choose the correct answer.</h3>`,
            text: `
                <div>
                  <p>1. On Sunday, Tom gets up at 10 o'clock.<br>The correct answer is ‘at’. We always use ‘at’ with time: at 7 o’clock, at 10.30 in the morning.</p>
                  <p>2. He has breakfast at 11.30 and then he telephones his mother in Scotland.<br>The correct answer is ‘his’. Tom is a man, so we use a pronoun ‘his’ when we talk about a man/a boy: his mother, his friend.</p>
                  <p>3.  In the afternoon, at 1 pm, Tom plays tennis with his sister and after that, they have  dinner in a restaurant.<br>The correct answer is ‘have’. Have dinner = eat dinner. In the restaurant, visitors don’t cook. And we can’t say ‘do dinner’ in English.</p>
                  <p>4. They talk and listen to music.<br>The correct answer is ‘listen’. We use the verb ‘listen’ with to. We can hear music and play music, but we listen to music.</p>
                </div>
            `,
          },

          uk : {
            title: `<h2>Завдання 2.</h2><h3>Для питань 1-4, обери правильну відповідь.</h3>`,

            text: `
                <div>
                  <p>1. On Sunday, Tom gets up at 10 o'clock.<br>Правильна відповідь ‘at’. Ми завжди використовуємо ‘at’ коли говоримо про час: at 7 o’clock, at 10.30 in the morning.</p>
                  <p>2. He has breakfast at 11.30 and then he telephones his mother in Scotland.<br>Правильна відповідь ‘his’. Том - це чоловік, ми використовуємо займенник ‘his’ (його), коли говоримо про чоловіка або хлопчика: his mother, his friend.</p>
                  <p>3.  In the afternoon, at 1 pm, Tom plays tennis with his sister and after that, they have  dinner in a restaurant.<br>Правильна відповідь ‘have’. Have dinner = eat dinner. В ресторані, відвідувачі не готують їжу. Також, не можна сказати ‘do dinner’ в англійській мові. </p>
                  <p>4. They talk and listen to music.<br>Правильна відповідь ‘listen’. Ми вживаємо дієслово ‘listen’ з часткою to. Можна сказати ‘hear music’ і ‘play music’, але тільки ‘listen’ вживається з to.</p>
                </div>
            `,
          }
        };

        this.switchLang ? this.t = translates['uk'] : this.t = translates['en'];
        
      },

      checkAnswers() {
        let arr = [];
        let answers = [];

        for (let key in this.select) {
          arr.push(this.select[key]);
        }

        this.correct.map((el, i) => answers.push(el === arr[i] ? true : false ));
        return answers;
      },
      
      showAnswer() {
        this.comment = true;
        this.hideBt = true;
        this.disabledSelect = true;
        this.$store.dispatch('tasks/addAnswer', this.checkAnswers());
      },

      next() {

      }
    },
    computed: {
        disabledBt() {
          let arr = [];

          for (let key in this.select) {
            arr.push(this.select[key]);
          }

          return arr.every(e => e != null) ? false : true;
        },

        answerIs() {
          let set = new Set(this.checkAnswers());
          let value;
        
          for (let key of set) {
            set.size == 1 ? value = key : value = false
          }
          
          return value ?
            `<p style="color: #57b894">Correct!</p>` :
            `<p style="color: #e61130">Wrong :(</p>`
        }
    }
  }
</script>


<style lang="scss" scoped>
.select-question {
  p {
    margin-bottom: 20px;

    &:last-child {margin-bottom: 0px;}
  }
}
.v-text-field__details {display: none;}
.select-question_input {
  display: inline-block;
  max-height: 60px;
  // width: 200px;
}
</style>