<template>
    <div class="task">
        <div class="task_title" v-html="t.title"></div>

    <div class="task-question task-question-dnd">
        <Container @drop="onDrop">            
          <Draggable v-for="(item, i) in answers" :key="i">
            <div class="draggable-item">
              {{item}}
            </div>
          </Draggable>
        </Container>
    </div>

    <v-btn 
        elevation="2" 
        @click="showAnswer" 
        color="#57b894" 
        :disabled="disabledBt"
        :class="[{hideBt: hideBt}, 'submitBt', 'main_button']"
    >Submit</v-btn>

    <div class="task-comment" v-if="comment">
        <div class="task-comment_head">
            <div class="correct" v-html="answerIs"></div>

            <v-sheet class="pa-5">
                <v-switch
                    @click="changeLang"
                    v-model="switchLang"
                    label="Translate"
                    inset
                ></v-switch>
            </v-sheet>
        </div>

        <div class="description" v-html="t.text"></div>

      <NextBt />
    </div>
    
  </div>
</template>

<script>
import { Container, Draggable } from "vue-smooth-dnd";
import NextBt from '@/components/NextBt';

const applyDrag = (arr, dragResult) => {
    const { removedIndex, addedIndex, payload } = dragResult
    if (removedIndex === null && addedIndex === null) return arr

    const result = [...arr]
    let itemToAdd = payload

    if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
    }

    if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
    }

    return result
}

export default {
    name: 'Task4',

    components: {Container, Draggable, NextBt}, 

    data: () => ({
      radioGroup: 0,
      switchLang: false,
      comment: false,
      disabledBt: true,
      hideBt: false,
      t: null,
      lang: 'en',
      answers: [
        'See you soon,',
        'Last week, we went on holiday.',
        'How are you?',
        'When can we meet? What about next week?',
        'Hi George,',
        'The weather was fine and the food was fantastic there! Also, I’ve got a souvenir for you.',
        'Love, Miranda'
      ],
      correct: [
        'Hi George,',
        'How are you?',
        'Last week, we went on holiday.',
        'The weather was fine and the food was fantastic there! Also, I’ve got a souvenir for you.',
        'When can we meet? What about next week?',
        'See you soon,',
        'Love, Miranda'
      ]
    }),

    beforeMount() {
      this.changeLang();
    },
    methods: {
      changeLang() {      
        const translates = {
          en : {
            title: `<h2>Task 4.</h2><h3>Write an e-mail. There are seven pieces of the e-mail. Put them in the correct order.</h3>`,
            text: `<div>
                <p>Hi George,<br>How are you?<br>Last week, we went on holiday. The weather was fine and the food was fantastic there! Also, I’ve got a souvenir for you. When can we meet? What about next week?<br>See you soon,<br>Love, <br>Miranda</p>
            </div>`
          },

          uk : {
            title: '<h2>Завдання 4.</h2><h3>Перед тобою сім части електронного листа. Постав їх у правильній послідовності.</h3>',
            text: `<div>
                <p>Hi George,<br>How are you?<br>Last week, we went on holiday. The weather was fine and the food was fantastic there! Also, I’ve got a souvenir for you. When can we meet? What about next week?<br>See you soon,<br>Love, <br>Miranda</p>
            </div>`
          }
        };

        this.switchLang ? this.t = translates['uk'] : this.t = translates['en'];
        
      },

      checkAnswers() {
        return this.correct.map((el, i) => el === this.answers[i] ? true : false );
      },
      
      showAnswer() {
        this.comment = true;
        this.hideBt = true;
        this.$store.dispatch('tasks/addAnswer', this.checkAnswers());
        // this.disabledBt = true;
        // document.querySelector('.submitBt').style.display = 'none';
        document.querySelector('.task-question-dnd').classList.add('task-question-dnd-submited');
      },
      onDrop(dropResult) {
        this.disabledBt = false;
        this.answers = applyDrag(this.answers, dropResult);
        document.body.className = '';
      }
    },
    computed: {
      answerIs() {
        let set = new Set(this.checkAnswers());
        let value;
      
        for (let key of set) {
          set.size == 1 ? value = key : value = false
        }
        
        return value ?
          `<p style="color: #57b894">Correct!</p>` :
          `<p style="color: #e61130">Wrong :(</p>`
      }
    }
}
</script>

<style lang="scss" scoped>
.draggable-item {
    background-color: #fff;
    border: 1px solid #979797;
    border-radius: 30px;
    max-width: 450px;
    // width: 100%;
    display: inline-block;
    margin-bottom: 20px;
    font-size: 18px;
    padding: 10px 20px;
    cursor: move;
}

.task-question-dnd-submited {
    pointer-events: none;

    .draggable-item {
        border: 1px solid rgba(0, 0, 0, 0.38);
        color: rgba(0, 0, 0, 0.38);
    }
}
</style>