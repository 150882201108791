<template>
  <div class="task">
    <div class="task_title" v-html="t.title"></div>

    <div class="task-question">
        <p>
            Thank you 
            <v-text-field 
              class="answers_input"
              v-model="input.input1"
              :disabled="inputDisabled"
              maxlength="9"
              label="1"></v-text-field>
            your letter. I’m happy that you 
            <v-text-field 
              class="answers_input"
              v-model="input.input2"
              :disabled="inputDisabled"
              maxlength="9"
              label="2"></v-text-field>
            coming to visit me in June.
        </p>
        <p>
            Yesterday, I met Eliza. 
            <v-text-field 
              class="answers_input"
              v-model="input.input3"
              :disabled="inputDisabled"
              maxlength="9"
              label="3"></v-text-field>
            you remember her? We went to the gallery and saw 
            <v-text-field 
              class="answers_input"
              v-model="input.input4"
              :disabled="inputDisabled"
              maxlength="9"
              label="4"></v-text-field>
            lot of paintings. Why don’t 
            <v-text-field 
              class="answers_input"
              v-model="input.input5"
              :disabled="inputDisabled"
              maxlength="9"
              label="5"></v-text-field>
            go there when you come?
        </p>
    </div>

    <v-btn 
        elevation="2" 
        @click="showAnswer" 
        color="#57b894" 
        :disabled="disabledBt"
        :class="[{hideBt: hideBt}, 'submitBt', 'main_button']"
    >Submit</v-btn>

    <div class="task-comment" v-if="comment">
        <div class="task-comment_head">
            <div class="correct" v-html="answerIs"></div>

            <v-sheet class="pa-5">
                <v-switch
                    @click="changeLang"
                    v-model="switchLang"
                    label="Translate"
                    inset
                ></v-switch>
            </v-sheet>
        </div>

        <div class="description" v-html="t.text"></div>

      <NextBt />
    </div>
    
  </div>
</template>

<script>
  import NextBt from '@/components/NextBt';
  
  export default {
    name: 'Task1',
    components: {NextBt},

    data: () => ({
      radioGroup: 0,
      switchLang: false,
      comment: false,
      hideBt: false,
      t: null,
      lang: 'en',
      input: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null
      },
      inputDisabled: false,
      correct: ['for','are','do','a','we']
    }),

    beforeMount() {
      this.changeLang();
    },

    methods: {
      changeLang() {      
        const translates = {
          en : {
            title: `<h2>Task 3.</h2><h3> For questions 1-5, write the correct answer. Write one word for each gap.</h3>`,
            text: `
                <div class="true">
                    <p>Here is why we need these words in the gaps. Read about each question to understand it better.</p>
                    <ul>
                        <li>1. thank you for your letter — use ‘for’ in the phrase ‘thank you for something’</li>
                        <li>2. you are coming to visit me in June — use ‘are’ with you in Present Continuous</li>
                        <li>3. Do you remember her? — use ‘do’ in Present Simple questions</li>
                        <li>4. a lot of paintings — use ‘a’ in the phrase ‘a lot of’</li>
                        <li>5. Why don’t we go there together? — use ‘why don’t we do something’ to propose something.</li>
                    </ul>
                </div>
            `,
          },

          uk : {
            title: `<h2>Завдання 3.</h2><h3>Для питань 1-5, напиши правильну відповідь. Напиши одне слово у кожен пропуск.</h3>`,

            text: `
                <div class="true">
                    <p>Тут ти можеш прочитати, чому ми використовуємо ці слова в пропусках. Прочитай кожне речення, щоб зрозуміти його краще. </p>
                    <ul>
                        <li>1. thank you for your letter — використовуємо ‘for’ у фразі ‘thank you for something’</li>
                        <li>2. you are coming to visit me in June — використовуємо ‘are’ разом з ‘you’ in Present Continuous</li>
                        <li>3. Do you remember her? — використовуємо ‘do’ in Present Simple (в питаннях) </li>
                        <li>4. a lot of paintings — використовуємо ‘a’ у фразі ‘a lot of’</li>
                        <li>5.  Why don’t we go there together? — використовуємо ‘why don’t we do something’ щоб запропонувати зробити щось.</li>
                    </ul>
                </div>
            `,
          }
        };

        this.switchLang ? this.t = translates['uk'] : this.t = translates['en'];
        
      },

      checkAnswers() {
        let arr = [];
        let answers = [];

        for (let key in this.input) {
          arr.push(this.input[key]);
        }

        this.correct.map((el, i) => answers.push(el.toLowerCase() === arr[i].toLowerCase() ? true : false ));
        return answers;
      },
      
      showAnswer() {
        this.comment = true;
        this.hideBt = true;
        this.inputDisableda = true;
        this.$store.dispatch('tasks/addAnswer', this.checkAnswers());
      }
    },
    computed: {
        disabledBt() {
          let arr = [];

          for (let key in this.input) {
            arr.push(this.input[key]);
          }

          return arr.every(e => e != null) ? false : true;
        },

        answerIs() {
          let set = new Set(this.checkAnswers());
          let value;
        
          for (let key of set) {
            set.size == 1 ? value = key : value = false
          }
          
          return value ?
            `<p style="color: #57b894">Correct!</p>` :
            `<p style="color: #e61130">Wrong :(</p>`
        }
    }
  }
</script>


<style lang="scss" scoped>
.select-question {
  p {
    margin-bottom: 20px;

    &:last-child {margin-bottom: 0px;}
  }
}
.v-text-field__details {display: none;}
.select-question_input {
  display: inline-block;
  max-height: 60px;
  // width: 200px;
}

.answers_input {
  width: 100px;
  display: inline-block;
  padding: 0 10px;
}
</style>