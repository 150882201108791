<template>
    <div>
        <div class="task_title" v-if="count === 0">
            <h2>Task 7.</h2><h3>Listen to three questions and choose the best option.</h3>
        </div>

        <div class="task-question-audio">
            <h4>{{count + 1}}.</h4>
            <vuetify-audio :file="audio" color="success" :downloadable="false"></vuetify-audio>
    
            <v-sheet class="d-flex flex-row-reverse">
                <v-switch
                    v-model="showScript"
                    label="Script"
                    inset
                ></v-switch>
            </v-sheet>
    
            <div class="audio_script" v-if="showScript">
            {{script}}
            </div>
            <v-radio-group v-model="radioGroup">
                <v-radio
                    v-for="(answer, i) in answers" :key="i"
                    :color="!disableRadio ? 'primary' : 'null'"
                    :label="answer"
                    :value="answer"
                    name="task-7-1"
                ></v-radio>
            </v-radio-group>
    
            <v-btn 
                elevation="2" 
                color="#57b894"
                @click="showAnswer" 
                :disabled="disabledBt"
                :class="[{hideBt: hideBt}, 'submitBt', 'main_button']"
            >Submit</v-btn>
    
            <div class="task-comment" v-if="comment">
                <div class="task-comment_head">
                    <div class="correct" v-html="answerIs"></div>
    
                    <v-sheet class="pa-5">
                        <v-switch
                            @click="changeLang"
                            v-model="switchLang"
                            label="Translate"
                            inset
                        ></v-switch>
                    </v-sheet>
                </div>
    
                <div class="description" v-html="t"></div>
    
                <NextBt v-if="count !== 2" />
                <End v-if="count === 2" />
            </div> 
        </div>
    </div>
</template>

<script>
import NextBt from '@/components/NextBt';
import End from '@/components/End';

export default {
    name: 'RadioAudioTask',
    
    components: {
        NextBt, End,
        VuetifyAudio: () => import('vuetify-audio'),
    },

    props: ['count', 'script', 'audio', 'answers', 'correct', 'translation'],

    data: () => ({
        showScript: false,
        disableRadio: false,
        radioGroup: 0,
        hideBt: false,
        switchLang: false,
        comment: false,
        t: null,
        lang: 'en',
    }),

    beforeMount() {
      this.changeLang();
    },

    methods: {
      changeLang() {     
        this.switchLang ? this.t = this.translation.uk : this.t = this.translation.en;
      },
      
      showAnswer() {
        this.comment = true;
        this.hideBt = true;
        this.disableRadio = true;
        this.$store.dispatch('tasks/addAnswer', [this.radioGroup == this.correct ? true : false]);
        // document.querySelector('.submitBt').style.display = 'none';
      }
    },
    computed: {
        disabledBt() {
            return this.radioGroup != 0 ? false : true
        },
        answerIs() {
            return this.radioGroup === this.correct ?
            `<p style="color: #57b894">Correct!</p>` :
            `<p style="color: #e61130">Wrong :(</p>`
        }
    }
}
</script>

<style lang="scss" scoped>
.task-question-audio {
  max-width: 600px;
  margin: 0 auto 50px;
  position: relative;

  h4 {
    position: absolute;
    top: -7px;
    left: -35px;
    font-size: 24px;

    @media(max-width: 480px) {
        font-size: 21px;
        position: relative;
        top: 0;
        left: 0;
    }
  }

  .radio_buttons_title {
    margin-top: 20px;
  }
}

.submitBt {
    margin-top: 20px !important;
}
</style>
