<template>
    <div class="task">
        <div class="task_title" v-html="t.title"></div>

    <div class="task-question-audio">
      <p>You will hear the advertisement about a new cinema. Listen to the text and fill in the gaps with one word or a number.</p>
      <vuetify-audio :file="file" color="success" :downloadable="false"></vuetify-audio>

      <v-sheet class="d-flex flex-row-reverse">
        <v-switch
            v-model="showScript"
            label="Script"
            inset
        ></v-switch>
      </v-sheet>

      <div class="audio_script" v-if="showScript">
        Dear visitors!<br>
        Our new cinema starts working next Monday, but you can buy tickets online on Saturday and Sunday and get a 15% discount. Also, you may have a 5% off the price if you buy three tickets or more. The cinema is at 35, Malcolm Street. It’s M A L C O L M.<br>
        See you there.
      </div>

      <div class="answers">
        <div class="title">New Cinema</div>

        <ul>
          <li>
            Opening day: 
            <v-text-field class="answers_input" maxlength="13" v-model="input.input1" :disabled="inputDisabled" label="1"></v-text-field>
          </li>
          <li>Discount for 3 tickets <v-text-field class="answers_input" maxlength="13" v-model="input.input2" :disabled="inputDisabled" label="2"></v-text-field> %</li>
          <li>Address: 35 <v-text-field class="answers_input" maxlength="13" v-model="input.input3" :disabled="inputDisabled" label="3"></v-text-field> Street</li>
        </ul>
      </div>
    </div>

    <v-btn 
        elevation="2" 
        @click="showAnswer" 
        color="#57b894" 
        :disabled="disabledBt"
        :class="[{hideBt: hideBt}, 'submitBt', 'main_button']"
    >Submit</v-btn>

    <div class="task-comment" v-if="comment">
      <div class="task-comment_head">
          <div class="correct" v-html="answerIs"></div>

          <v-sheet class="pa-5">
              <v-switch
                  @click="changeLang"
                  v-model="switchLang"
                  label="Translate"
                  inset
              ></v-switch>
          </v-sheet>
      </div>

      <div class="description" v-html="t.text"></div>

      <NextBt />
    </div>
  </div>
</template>

<script>
import audio from "@/assets/audio/Listening_PR2_2.mp3";
import NextBt from '@/components/NextBt';

export default {
    name: 'Task5',

    components: {
      NextBt,
      VuetifyAudio: () => import('vuetify-audio')
    },

    data: () => ({
      file: audio,
      switchLang: false,
      comment: false,
      hideBt: false,
      input: {
        input1: null,
        input2: null,
        input3: null
      },
      t: null,
      lang: 'en',
      showScript: false,
      inputDisabled: false,
      correct: ['Monday','5','malcolm']
    }),

    beforeMount() {
      this.changeLang();
    },
    methods: {
      changeLang() {      
        const translates = {
          en : {
            title: `<h2>Task 5.</h2><h3>For each question (1-3), write the correct answer in the gap. Write one word or a number or a date or a time.</h3>`,
            text: `<div>
              <p>
                Question 1: Opening day: Monday<br>
                <strong>This is a clue:</strong> Our new cinema starts working next Monday<br>
                <strong>This is a distractor:</strong> but you can buy tickets online on Saturday and Sunday
              </p>
              <p>
                Question 2: Discount for 3 tickets:  5%<br>
                <strong>This is a clue:</strong> Also, you may have a 5% off the price if you buy three tickets or more. <br>
                <strong>This is a distractor:</strong> but you can buy tickets online on Saturday and Sunday and get a 15% discount. 
              </p>
              <p>
                Question 3: Address: 35, Malcolm Street.
              </p>
              </br>
              <p>
                This task checks your knowledge of English letters. So, you should revise the ABC before the exam.
              </p>
            </div>`
          },

          uk : {
            title: '<h2>Завдання 5.<h3>Для кожного питання (1-3), напишіть правильну відповідь у пропусках. Напишіть одне слово обо одну цифру, або дату. </h3>',
            text: `<div>
              <p>
                Питання 1: Opening day: Monday<br>
                <strong>Правильна відповідь:</strong> Our new cinema starts working next Monday<br>
                <strong>Відволікаюча відповідь:</strong> but you can buy tickets online on Saturday and Sunday. В тексті йдеться про те, що кінотеатр відкривається в понеділок, відволікаючими словами є субота та неділя. Але в ці дні можна купити квитки онлайн.
              </p>
              <p>
                Питання 2: Discount for 3 tickets:  5%<br>
                <strong>Правильна відповідь:</strong> Also, you may have a 5% off the price if you buy three tickets or more. <br>
                <strong>Відволікаюча відповідь:</strong> but you can buy tickets online on Saturday and Sunday and get a 15% discount. 5% знижки діють, якщо купити три і більше квитків. Відволікаючою цифрою є 15%, але цю знижку можна отримати, якщо купити квитки онлайн.
              </p>
              <p>
                Питання 3: Address: 35, Malcolm Street.
              </p>
              <br>
              <p>
                Завдання такого типу часто перевіряють, як кандидат знає літери, тому перед іспитом треба повторити англійський алфавіт.
              </p>
            </div>`
          }
        };

        this.switchLang ? this.t = translates['uk'] : this.t = translates['en'];
      },

      checkAnswers() {
        let arr = [];
        let answers = [];
        
        for (let key in this.input) {
          arr.push(this.input[key]);
        }

        this.correct.map((el, i) => answers.push(el.toLowerCase() === arr[i].toLowerCase() ? true : false ));
        console.log('answers', answers)
        return answers;
      },
      
      showAnswer() {
        this.comment = true;
        this.hideBt = true;
        this.inputDisabled = true;
        this.$store.dispatch('tasks/addAnswer', this.checkAnswers());
        // this.disabledBt = true;
        // document.querySelector('.submitBt').style.display = 'none';
      }
    },
    computed: {
      disabledBt() {
        let arr = [];

        for (let key in this.input) {
          arr.push(this.input[key]);
        }

        return arr.every(e => e != null) ? false : true;
      },

      answerIs() {
        let set = new Set(this.checkAnswers());
        let value;
        
        for (let key of set) {
          set.size == 1 ? value = key : value = false
        }
        
        return value ?
          `<p style="color: #57b894">Correct!</p>` :
          `<p style="color: #e61130">Wrong :(</p>`
      }
    }
}
</script>

<style lang="scss" scoped>
.task-question-audio {
  max-width: 600px;
  margin: 0 auto;
}

.audio_script {
  font-family: $italic;
}

.answers {
  max-width: 600px;
  margin: 20px auto 30px;
  border: solid 1px #979797;
  background-color: #f4f4f4;
  position: relative;
  padding: 50px 60px;

  .title {
    text-align: center;
    margin-bottom: 20px;
  }

  .answers_input {
    display: inline-block;
    padding: 0 10px;
  }
}
</style>