<template>
  <div class="wrapper">
    <div class="tasks_content">
      <h2>Task 1.</h2>
      <h3>For questions 1–3, choose the correct answer.</h3>
      <RadioTask
        task='<span>1.</span><p style="font-size: 30px; text-align: center">Car Wash ￡5<br>￡4 at night<br>(cars only, no motorbikes) </p>'
        :answers="[
          'you can wash your motorbike here',
          'the price at night is lower',
          'you can’t wash your car at night',
        ]"
        correct="the price at night is lower"
        :translation="{
          en: {
            text: `<div class=&quot;true&quot;>
                            <p>This option is correct because it has the same meaning as the announcement:</p>
                            <p><i>the price at night is lower</i> = ￡4 at night → it’s cheaper than during the day (￡5)</p>
                        </div>
    
                        <div class=&quot;false&quot;>
                            <p>These two options are wrong.</p>
                            <p><i>You can wash your motorbike here.</i> The text says that you can’t wash your motorbike (cars only, no motorbikes)</p>
                            <p><i>You can’t wash your car at night.</i> The text says that you can do it and it’s cheaper</p>
                        </div>`,
          },
          uk: {
            text: `<div class=&quot;true&quot;>
                        <p>Ця відповідь є правильною, оскільки має однакове значення з оголошенням:</p>
                        <p><i>вночі ціна нижча</i> = 4 фунта, а протягом дня 5 фунтів.</p>
                    </div>
    
                    <div class=&quot;false&quot;>
                        <p>Ці дві відповіді неправильні:</p>
                        <p><i>Ти можеш помити тут свій мотоцикл.</i> В тексті оголошення написано, що ти не можеш мити мотоцикли, тільки автомобілі.</p>
                        <p><i>Ти не можеш мити машину вночі.</i> Ти можеш це зробити і це коштує менше.</p>
                    </div>`,
          },
        }"
      />

      <RadioTask
        class="hidden_task"
        task="<span>2.</span><p>Dear Ms. Right,<br>My son Harry is still ill and can’t attend classes this week.<br>I’d like to know if he can take the exams next week.<br>Tell me if any documents are needed.<br><br><br>Regards,<br>Amy Watson</p>"
        :answers="[
          'Amy is ill and can’t take the exams.',
          'Amy asks if her son may take exams later.',
          'Ms. Right says that Harry can’t come to school.',
        ]"
        correct="Amy asks if her son may take exams later."
        :translation="{
          en: {
            text: `<div class=&quot;true&quot;>
                        <p>This option is correct because it has the same meaning as the message:</p>
                        <p><i>Amy asks if her son may take exams later.</i> = I’d like to know if he can take the exams next week.</p>
                    </div>
                    <div class=&quot;false&quot;>
                        <p>These two options are wrong.</p>
                        <p><i>Amy is ill and can’t take the exams.</i> The text says that Amy’s son is ill, not Amy</p>
                        <p><i>Ms. Right says that Harry can’t come to school.</i> Ms. Right is a teacher, she doesn’t ask anything about Harry; Amy asks about Harry. </p>
                    </div>`,
          },

          uk: {
            text: `<div class=&quot;true&quot;>
                        <p>Ця відповідь є правильною, оскільки має однакове значення з повідомленням:</p>
                        <p><i>Емі питає, чи може її син скласти іспит пізніше.</i> = Я б хотіла дізнатись, чи може він скласти іспит наступного тижня</p>
                    </div>
                    <div class=&quot;false&quot;>
                        <p>Ці дві відповіді неправильні:</p>
                        <p><i>Емі хвора і не може скласти іспит</i> Емі не хвора, хворий її син</p>
                        <p><i>Міс Райт каже, що Гаррі не може прийти до школи</i> Міс Райт - це вчителька, вона нічого не питає про Гаррі, Емі питає про нього.</p>
                    </div>`,
          },
        }"
      />

      <RadioTask
        class="hidden_task"
        task='<span>3.</span><p style="font-size: 30px; text-align: center">Computer Room<br>Monday-Saturday 8.00 a.m-8p.m<br>no food or drinks allowed</p>'
        :answers="[
          'you can’t eat in the computer room',
          'you are allowed to drink here',
          'you can come before 8 a.m. here',
        ]"
        correct="you can’t eat in the computer room"
        :translation="{
          en: {
            text: `<div class=&quot;true&quot;>
                        <p>This option is correct because it has the same meaning as the announcement:</p>
                        <p><i>you can’t eat in the computer room</i> = no food or drinks allowed</p>
                    </div>
                    <div class=&quot;false&quot;>
                        <p>These two options are wrong.</p>
                        <p><i>you are allowed to drink here.</i> Allowed=can/may; the text says that you can’t drink in this room  </p>
                        <p><i>you can come before 8 a.m. here.</i> The text says that the room opens at 8.00, so you can’t come earlier; before = earlier</p>
                    </div>`,
          },

          uk: {
            text: `<div class=&quot;true&quot;>
                        <p>Ця відповідь є правильною, оскільки має однакове значення з оголошенням:</p>
                        <p><i>не можна їсти в класі</i>  = їжа чи напої не дозволяються.</p>
                    </div>
                    <div class=&quot;false&quot;>
                        <p>Ці дві відповіді неправильні:</p>
                        <p><i>тут можна пити/вживати напої.</i> Allowed = можна/дозволено; в оголошенні написано, що вживати напої не можна.</p>
                        <p><i>Ти можеш прийти сюди до 8 години ранку.</i> Зал починає працювати з 8 години ранку, тож приїхати раніше не можна; before = до (якогось часу); earlier = раніше </p>
                    </div>`,
          },
        }"
      />

      <Task2 class="hidden_task" />

      <Task3 class="hidden_task" />

      <Task4 class="hidden_task" />

      <Task5 class="hidden_task" />

      <Task6 class="hidden_task" />

      <Task7 />
    </div>
  </div>
</template>

<script>
import { sync } from "vuex-pathify";
// import Task1 from '@/components/tasks/Task1';
import RadioTask from "@/components/tasks/RadioTask";
import Task2 from "@/components/tasks/Task2";
import Task3 from "@/components/tasks/Task3";
import Task4 from "@/components/tasks/Task4";
import Task5 from "@/components/tasks/Task5";
import Task6 from "@/components/tasks/Task6";
import Task7 from "@/components/tasks/Task7";

export default {
  components: {
    // Task1, Task2,
    RadioTask,
    Task2,
    Task3,
    Task4,
    Task5,
    Task6,
    Task7,
  },
  computed: {
    answers: sync("tasks/answers"),
  },
  mounted() {
    this.answers = [];
  },
};
</script>

<style lang="scss">
.hidden_task {
  display: none;
}
.tasks_content {
  max-width: 800px;
  min-width: 320px;
  width: 100%;
  margin: 0 auto;
  padding: 80px 0 100px;

  h2 {
    font-size: 24px;
    font-family: $bold;
  }

  h3 {
    font-size: 24px;
    margin-bottom: 30px;
  }

  i {
    font-family: $italic;
  }
}
.task {
  margin-bottom: 80px;
}

.task-comment_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  border-top: 1px solid #e0e0e0;

  .correct {
    font-size: 21px;
  }
}

.description {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 30px;

  div {
    margin-bottom: 30px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
  p {
    margin-bottom: 10px;
    font-size: 18px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
