<template>
  <div class="task">
    <div class="checkbox_task-question" v-html="task"></div>

    <div class="checkbox_task-answers">
      <v-radio-group v-model="radioGroup">
        <v-radio
            v-for="(answer, i) in answers" :key="i"
            :color="!disableRadio ? 'primary' : 'null'"
            :disabled="disableRadio"
            name="reading-part-1-task-1"
            :label="answer"
            :value="answer"
        ></v-radio>
      </v-radio-group>
    </div>

    <v-btn 
        elevation="2" 
        color="#57b894"
        @click="showAnswer" 
        :disabled="disabledBt"
        :class="[{hideBt: hideBt}, 'submitBt', 'main_button']"
    >Submit</v-btn>

    <div class="task-comment" v-if="comment">
        <div class="task-comment_head">
            <div class="correct" v-html="answerIs"></div>

            <v-sheet class="pa-5">
                <v-switch
                    @click="changeLang"
                    v-model="switchLang"
                    label="Translate"
                    inset
                ></v-switch>
            </v-sheet>
        </div>

        <div class="description" v-html="t.text"></div>

      <NextBt />
    </div>
    
  </div>
</template>


<script>
  import NextBt from '@/components/NextBt';

  export default {
    name: 'Task2',

    components: {NextBt},

    props: ['task', 'num', 'answers', 'correct', 'translation'],

    data: () => ({
      radioGroup: 0,
      switchLang: false,
      comment: false,
      hideBt: false,
      disableRadio: false,
      disableSubmit: false,
      t: null,
      lang: 'en'
    }),

    beforeMount() {
      this.changeLang();
    },

    methods: {
      changeLang() {      
        this.switchLang ? this.t = this.translation['uk'] : this.t = this.translation['en'];   
      },
      
      showAnswer() {
        this.comment = true;
        this.disableRadio = true;
        this.hideBt = true;
        this.$store.dispatch('tasks/addAnswer', [this.radioGroup == this.correct ? true : false]);
      }
    },
    computed: {
        disabledBt() {
            return this.radioGroup != 0 ? false : true
        },

        answerIs() {
            return this.radioGroup === this.correct ?
                `<p style="color: #57b894">Correct!</p>` :
                `<p style="color: #e61130">Wrong :(</p>`
        }
    }
  }
</script>


<style lang="scss">
.checkbox_task {
  margin-bottom: 50px;
}
.checkbox_task-question {
  max-width: 600px;
  margin: 0 auto 30px;
  border: solid 1px #979797;
  background-color: #f4f4f4;
  position: relative;

  p {
    padding: 50px 60px;
    font-size: 20px;
    margin: 0;
  }

  span {
    font-size: 24px;
    position: absolute;
    top: -7px;
    left: -35px;

    @media(max-width: 480px) {
      font-size: 21px;
      position: relative;
      top: 0;
      left: 0;
    }
  }
}
</style>