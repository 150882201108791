<template>
    <div class="task">
        <RadioAudioTask
            v-for="(item, i) in arr"
            :key="i"
            :count="i"
            :script="item.script"
            :audio="item.audio"
            :answers="item.answers"
            :correct="item.correct"
            :translation="item.translation"
            class="hidden_task"
        />
  </div>
</template>

<script>
import audio1 from "@/assets/audio/Speaking_When_did_you_last.mp3";
import audio2 from "@/assets/audio/Speaking_Who_do_you_go.mp3";
import audio3 from "@/assets/audio/Speaking_What_do_you_like.mp3";
import RadioAudioTask from "@/components/tasks/RadioAudioTask";

export default {
    name: 'Task7',

    components: {RadioAudioTask},

    data: () => ({
      audio: [audio1, audio2, audio3],
      
      arr: [
        {
          'script': 'When did you last watch a film?',
          'answers': ['I watched an interesting film at the cinema.','I like comedies because they are funny.','I watched a good film two weeks ago.'],
          'correct': 'I watched a good film two weeks ago.',
          'translation': {
              'en': 'The correct option is, it says WHEN the speaker watched a film (two weeks ago).',
              'uk': 'Правильна відповідь, оскільки ця відповідь пояснює  КОЛИ спікер дивився фільм. (два тижні тому)'
          },
          'audio': audio1
        },

        {
          'script': 'Who do you go shopping with?',
          'answers': ['I go shopping every week, usually on Sundays.','We usually go shopping with my sister Melanie.','My boyfriend usually goes shopping for food.'],
          'correct': 'We usually go shopping with my sister Melanie.',
          'translation': {
              'en': 'The correct option is, it says who the speaker does shopping WITH (with his/her sister).',
              'uk': 'Правильна відповідь, оскільки ця відповідь пояснює З КИМ спікер ходить в магазин. (з сестрою)'
          },
          'audio': audio2
        },

        {
          'script': 'What do you like doing in your free time?',
          'answers': ['I don’t like going to the cinema, because I have a lot of films at home.','I’m going to visit my friends at the weekend and have a party with them.','I like spending time with my family, we usually go out or play games at home.'],
          'correct': 'I like spending time with my family, we usually go out or play games at home.',
          'translation': {
              'en': 'The correct option is, it ways WHAT the speaker likes doing in his/her free time (spend time with a family, go out, play games)',
              'uk': 'Правильна відповідь, оскільки ця відповідь пояснює ЩО любить робити спікер у вільний час. (проводити час з родиною, гуляти, грати в ігри)'
          },
          'audio': audio3
        }
      ],
      correct: ['I watched a good film two weeks ago.', 'We usually go shopping with my sister Melanie.', 'I like spending time with my family, we usually go out or play games at home.']
    }),
}
</script>

<style lang="scss" scoped>
.audio_script {
  font-family: $italic;
}

.answers {
  max-width: 600px;
  margin: 20px auto 30px;
  border: solid 1px #979797;
  background-color: #f4f4f4;
  position: relative;
  padding: 50px 60px;

  .title {
    text-align: center;
    margin-bottom: 20px;
  }

  .answers_input {
    display: inline-block;
    padding: 0 10px;
  }
}
</style>