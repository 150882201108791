<template>
  <div>
    <v-btn
      v-if="showButton"
      elevation="2"
      color="#57b894"
      :disabled="disabledBt"
      :class="[{hideBt: disabledBt}, 'main_button', 'finishBt']"
      @click="submit"
    >Finish</v-btn>

    <!-- <div>Percentage of correct answers: {{correctAnswersPercentage}}%</div>
    <br />
    <div>Correct Answers: {{correctAnswersNumber}}</div>
    <br />
    <div>Array of results: {{answersFlat}}</div>-->
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import { submitAnswers } from "@/services/apiClient";
import { clearStorage } from "@/store/helpers/persistedState";

export default {
  name: "End",
  data: () => ({
    showButton: true,
  }),
  computed: {
    answers: get("tasks/answers"),
    email: get("user/email"),
    loading: sync("app/loading"),

    answersFlat() {
      return this.answers.flat(1);
    },

    disabledBt() {
      return this.answers.length < 11;
    },

    correctAnswersNumber() {
      return this.answersFlat.filter(Boolean).length;
    },

    correctAnswersPercentage() {
      return Math.round(
        (this.correctAnswersNumber / this.answersFlat.length) * 100
      );
    },
  },

  methods: {
    async submit() {
      this.loading = true;
      try {
        const { data } = await submitAnswers({
          email: this.email,
          correct: this.correctAnswersNumber,
        });
        console.log(data);

        if (data === "Success") {
          this.showButton = false;
          clearStorage();
          this.$router.push({ name: "Finish" });
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>