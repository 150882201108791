<template>
    <v-btn 
        elevation="2" 
        color="#57b894"
        :class="[{hideBt: hideBt}, 'next_button', 'main_button']"
        @click="next"
    >Next</v-btn>
</template>


<script>
  export default {
    name: 'NextBt',

    data: () => ({
        hideBt: false
    }),

    methods: {
      next() {
        const firstHiddenTask = document.querySelector(".hidden_task");

        if (firstHiddenTask !== undefined && firstHiddenTask !== null) {
          firstHiddenTask.classList.remove("hidden_task");
          window.scrollTo({ top: firstHiddenTask.offsetTop - 86, behavior: 'smooth'});
          this.hideBt = true;
        }
      }
    }
  }
</script>