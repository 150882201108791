<template>
    <div class="task">
        <div class="task_title" v-html="t.title"></div>

    <div class="task-question-audio">
      <p></p>
      <vuetify-audio :file="file" color="success" :downloadable="false"></vuetify-audio>

      <v-sheet class="d-flex flex-row-reverse">
        <v-switch
            v-model="showScript"
            label="Script"
            inset
        ></v-switch>
      </v-sheet>

      <div class="audio_script" v-if="showScript">
        Maria? Hi, it’s Lucy.<br>Thank you for your advice, the exhibition is really great. There weren’t many people there, and the staff was friendly, but the music was very loud for some reason. But what I really enjoyed was the present I won in a lottery there — a free ticket for their next exhibition. Why don’t we go there together? 
      </div>

      <v-radio-group v-model="radioGroup">
        <v-radio
            v-for="(answer, i) in answers" :key="i"
            :color="!disableRadio ? 'primary' : 'null'"
            :disabled="disableRadio"
            name="reading-part-1-task-1"
            :label="answer"
            :value="answer"
        ></v-radio>
      </v-radio-group>
    </div>

    <v-btn 
        elevation="2" 
        @click="showAnswer" 
        color="#57b894" 
        :disabled="disabledBt"
        :class="[{hideBt: hideBt}, 'submitBt', 'main_button']"
    >Submit</v-btn>

    <div class="task-comment" v-if="comment">
        <div class="task-comment_head">
            <div class="correct" v-html="answerIs"></div>

            <v-sheet class="pa-5">
                <v-switch
                    @click="changeLang"
                    v-model="switchLang"
                    label="Translate"
                    inset
                ></v-switch>
            </v-sheet>
        </div>

        <div class="description" v-html="t.text"></div>

      <NextBt />
    </div>
    
  </div>
</template>

<script>
import audio from "@/assets/audio/Listening_PR2_4_2.mp3";
import NextBt from '@/components/NextBt';

export default {
    name: 'Task5',

    components: {
      NextBt,
      VuetifyAudio: () => import('vuetify-audio')
    },

    data: () => ({
      file: audio,
      radioGroup: 0,
      switchLang: false,
      comment: false,
      t: null,
      lang: 'en',
      showScript: false,
      disableRadio: false,
      hideBt: false,
      answers: [
        'the present',
        'the music',
        'the friendly staff',
      ],
      correct: 'the present'
    }),

    beforeMount() {
      this.changeLang();
    },
    methods: {
      changeLang() {      
        const translates = {
          en : {
            title: `<h2>Task 6.</h2><h3>You will hear a woman talking about her visit to the art gallery. What did she like the most? Listen and choose the correct answer.</h3>`,
            text: `<div>
              <p>The correct answer is <strong>the present</strong> as she says: ‘But what I really enjoyed was the present’.<br>The music is not the answer because it was loud and the woman didn’t like it.<br>The friendly staff is not the answer too, because although the woman mentioned the friendly staff, she doesn't say that she liked it the most.</p>
            </div>`
          },

          uk : {
            title: `<h2>Task 6.</h2><h3>You will hear a woman talking about her visit to the art gallery. What did she like the most? Listen and choose the correct answer.</h3>`,
            text: `<div>
              <p>Правильна відповідь <strong>‘подарунок’</strong>, оскільки спікер каже, що саме це їй сподобалось найбільше.<br>‘Музика’ - це неправильна відповідь, оскільки вона була голосною, і спікеру це не сподобалось.<br>‘Дружній персонал’ - це також неправильна відповідь, оскільки спікер говорить про них, але не каже, що персонал -це те, що сподобалось їй найбільше.</p>
            </div>`
          }
        };

        this.switchLang ? this.t = translates['uk'] : this.t = translates['en'];
        
      },
      
      showAnswer() {
        this.comment = true;
        this.hideBt = true;
        this.disableRadio = true;
        this.$store.dispatch('tasks/addAnswer', [this.radioGroup == this.correct ? true : false]);
        // document.querySelector('.submitBt').style.display = 'none';
      }
    },
    computed: {
        disabledBt() {
            return this.radioGroup != 0 ? false : true
        },
        answerIs() {
            return this.radioGroup === this.correct ?
            `<p style="color: #57b894">Correct!</p>` :
            `<p style="color: #e61130">Wrong :(</p>`
        }
    }
}
</script>

<style lang="scss" scoped>
.task-question-audio {
  max-width: 600px;
  margin: 0 auto;
}

.audio_script {
  font-family: $italic;
}

.answers {
  max-width: 600px;
  margin: 20px auto 30px;
  border: solid 1px #979797;
  background-color: #f4f4f4;
  position: relative;
  padding: 50px 60px;

  .title {
    text-align: center;
    margin-bottom: 20px;
  }

  .answers_input {
    display: inline-block;
    padding: 0 10px;
  }
}
</style>