var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"tasks_content"},[_c('h2',[_vm._v("Task 1.")]),_c('h3',[_vm._v("For questions 1–3, choose the correct answer.")]),_c('RadioTask',{attrs:{"task":"<span>1.</span><p style=\"font-size: 30px; text-align: center\">Car Wash ￡5<br>￡4 at night<br>(cars only, no motorbikes) </p>","answers":[
        'you can wash your motorbike here',
        'the price at night is lower',
        'you can’t wash your car at night' ],"correct":"the price at night is lower","translation":{
        en: {
          text: "<div class=\"true\">\n                          <p>This option is correct because it has the same meaning as the announcement:</p>\n                          <p><i>the price at night is lower</i> = ￡4 at night → it’s cheaper than during the day (￡5)</p>\n                      </div>\n  \n                      <div class=\"false\">\n                          <p>These two options are wrong.</p>\n                          <p><i>You can wash your motorbike here.</i> The text says that you can’t wash your motorbike (cars only, no motorbikes)</p>\n                          <p><i>You can’t wash your car at night.</i> The text says that you can do it and it’s cheaper</p>\n                      </div>",
        },
        uk: {
          text: "<div class=\"true\">\n                      <p>Ця відповідь є правильною, оскільки має однакове значення з оголошенням:</p>\n                      <p><i>вночі ціна нижча</i> = 4 фунта, а протягом дня 5 фунтів.</p>\n                  </div>\n  \n                  <div class=\"false\">\n                      <p>Ці дві відповіді неправильні:</p>\n                      <p><i>Ти можеш помити тут свій мотоцикл.</i> В тексті оголошення написано, що ти не можеш мити мотоцикли, тільки автомобілі.</p>\n                      <p><i>Ти не можеш мити машину вночі.</i> Ти можеш це зробити і це коштує менше.</p>\n                  </div>",
        },
      }}}),_c('RadioTask',{staticClass:"hidden_task",attrs:{"task":"<span>2.</span><p>Dear Ms. Right,<br>My son Harry is still ill and can’t attend classes this week.<br>I’d like to know if he can take the exams next week.<br>Tell me if any documents are needed.<br><br><br>Regards,<br>Amy Watson</p>","answers":[
        'Amy is ill and can’t take the exams.',
        'Amy asks if her son may take exams later.',
        'Ms. Right says that Harry can’t come to school.' ],"correct":"Amy asks if her son may take exams later.","translation":{
        en: {
          text: "<div class=\"true\">\n                      <p>This option is correct because it has the same meaning as the message:</p>\n                      <p><i>Amy asks if her son may take exams later.</i> = I’d like to know if he can take the exams next week.</p>\n                  </div>\n                  <div class=\"false\">\n                      <p>These two options are wrong.</p>\n                      <p><i>Amy is ill and can’t take the exams.</i> The text says that Amy’s son is ill, not Amy</p>\n                      <p><i>Ms. Right says that Harry can’t come to school.</i> Ms. Right is a teacher, she doesn’t ask anything about Harry; Amy asks about Harry. </p>\n                  </div>",
        },

        uk: {
          text: "<div class=\"true\">\n                      <p>Ця відповідь є правильною, оскільки має однакове значення з повідомленням:</p>\n                      <p><i>Емі питає, чи може її син скласти іспит пізніше.</i> = Я б хотіла дізнатись, чи може він скласти іспит наступного тижня</p>\n                  </div>\n                  <div class=\"false\">\n                      <p>Ці дві відповіді неправильні:</p>\n                      <p><i>Емі хвора і не може скласти іспит</i> Емі не хвора, хворий її син</p>\n                      <p><i>Міс Райт каже, що Гаррі не може прийти до школи</i> Міс Райт - це вчителька, вона нічого не питає про Гаррі, Емі питає про нього.</p>\n                  </div>",
        },
      }}}),_c('RadioTask',{staticClass:"hidden_task",attrs:{"task":"<span>3.</span><p style=\"font-size: 30px; text-align: center\">Computer Room<br>Monday-Saturday 8.00 a.m-8p.m<br>no food or drinks allowed</p>","answers":[
        'you can’t eat in the computer room',
        'you are allowed to drink here',
        'you can come before 8 a.m. here' ],"correct":"you can’t eat in the computer room","translation":{
        en: {
          text: "<div class=\"true\">\n                      <p>This option is correct because it has the same meaning as the announcement:</p>\n                      <p><i>you can’t eat in the computer room</i> = no food or drinks allowed</p>\n                  </div>\n                  <div class=\"false\">\n                      <p>These two options are wrong.</p>\n                      <p><i>you are allowed to drink here.</i> Allowed=can/may; the text says that you can’t drink in this room  </p>\n                      <p><i>you can come before 8 a.m. here.</i> The text says that the room opens at 8.00, so you can’t come earlier; before = earlier</p>\n                  </div>",
        },

        uk: {
          text: "<div class=\"true\">\n                      <p>Ця відповідь є правильною, оскільки має однакове значення з оголошенням:</p>\n                      <p><i>не можна їсти в класі</i>  = їжа чи напої не дозволяються.</p>\n                  </div>\n                  <div class=\"false\">\n                      <p>Ці дві відповіді неправильні:</p>\n                      <p><i>тут можна пити/вживати напої.</i> Allowed = можна/дозволено; в оголошенні написано, що вживати напої не можна.</p>\n                      <p><i>Ти можеш прийти сюди до 8 години ранку.</i> Зал починає працювати з 8 години ранку, тож приїхати раніше не можна; before = до (якогось часу); earlier = раніше </p>\n                  </div>",
        },
      }}}),_c('Task2',{staticClass:"hidden_task"}),_c('Task3',{staticClass:"hidden_task"}),_c('Task4',{staticClass:"hidden_task"}),_c('Task5',{staticClass:"hidden_task"}),_c('Task6',{staticClass:"hidden_task"}),_c('Task7')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }